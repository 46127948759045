<script>
import { mapGetters } from 'vuex'

export default {
    name: "experienciaProfesionalPostulacion",
    data(){
        return {
            baseExperiencia: {
                nombreEmpresa: null,
                mesesLaborados: null,
                fechaInicio: null,
                fechaFin: null,
                cargo: null,
                motivoRetiro: null,
                telefono: null,
                jefeInmediato: null,
                paisId: null,
                departamentoId: null,
                municipioId: null,
            },
            departamentos: [],
            municipios: []
        }
    },
    mounted() {
        if( !this.$store.getters.resumenExperiencia.length ) {
            this.agregarRegistro()
        }
    },
    computed: {
        ...mapGetters({
            listaPaises: 'getPaises',
            listaDepartamentos: 'getDepartamentos',
            listaMunicipios: 'getMunicipios',
            resumenExperiencia: 'resumenExperiencia'
        }),
    },
    methods: {
        assertData(idx) {
            console.log(this.$store.getters.resumenExperiencia[idx]);
        },
        agregarRegistro() {
            this.$store.commit('appendElement', { key: 'resumenExperiencia', newElement: this.baseExperiencia} )
        },
        eliminarRegistro(idx) {
            this.departamentos.splice(idx, 1)
            this.municipios.splice(idx, 1)
            this.$store.commit('deleteElementAt', { key: 'resumenExperiencia', idx})
        },
        saveAndContinue() {
            const inputs = document.querySelectorAll('input, select')
            try {
                inputs.forEach(input => {
                    if (!input.checkValidity()) {
                        input.reportValidity()
                        throw new Error('Formulario incompleto')
                    }
                })

                
                let data = [
                    ...this.resumenExperiencia.map( formacion => {
                        formacion.PostulacionId = this.$store.getters.postulacionId
                        return formacion
                    })
                ]


                this.$store.getters.fetchPost({ path: 'Postulacion/ExperienciaProfecional', data })
                .then( response => {
                    if( response.ok )
                    {
                        return response.json()
                    }
                    throw new Error("No se ha podido guardar educación formal")
                })
                .then( result => {
                    console.log(result);
                    this.$emit('nextStep')
                })
                .catch( error => console.log(error))


            } catch (error) {
                console.log(error);
                return
            }
        },
        setDepartamentosInElement( idx, paisId = null){
            this.$store.getters.fetchGet({ path: 'DepartamentoPais/ListByPais/' + paisId })
            .then(response => {
                    if(response.ok){
                        return response.json()
                    }
                    throw new Error(response.status)
                })
                .then( data => {
                    if( idx > this.departamentos.length - 1) {
                        this.departamentos.push(data)
                    } else {
                        this.departamentos[idx] = data
                    }
                    this.departamentos = [...this.departamentos]
                })
                .catch(error => console.error(error))
        },
        setMunicipiosInElement( idx, departamentoId = null){
            this.$store.getters.fetchGet({ path: 'Municipio/ListByDepartamento/' + departamentoId })
            .then(response => {
                    if(response.ok){
                        return response.json()
                    }
                    throw new Error(response.status)
                })
                .then( data => {
                    if( idx > this.municipios.length - 1) {
                        this.municipios.push(data)
                    } else {
                        this.municipios[idx] = data
                    }
                    this.municipios = [...this.municipios]
                })
                .catch(error => console.error(error))
        }
    }
}

</script>
<template>
    <div>
        <b-form @submit.prevent="assertData(idx)" v-for="(experiencia, idx) in resumenExperiencia" :key="'experiencia ' + idx">
            <b-row class="pb-1 px-3">
                <div class="d-flex align-items-center justify-content-between mt-2" style="width: 100%">
                    <p class="text-center my-0">
                        <b>
                            Ref. Experiencia profecional {{ idx + 1 }}
                        </b>
                    </p>
                    <div class="pointer btn btn-outline-danger m-1" @click="eliminarRegistro(idx)">
                        <i class="fas fa-solid fa-trash px-1"></i>Eliminar
                    </div>
                </div>
            </b-row>

            <b-row class="pb-2">
                <b-col sm>
                    <label for="experienciaProfecionalNombre" class="form-label">Nombre de la empresa</label>
                    <b-form-input v-model="experiencia.nombreEmpresa" type="text" id="experienciaProfecionalNombre" required/>
                </b-col>
                <b-col sm>
                    <label for="experienciaProfecionalMeses" class="form-label">Meses laborados</label>
                    <b-form-input v-model="experiencia.mesesLaborados" type="number" id="experienciaProfecionalMeses" min="0" required/>
                </b-col>
            </b-row>
            <b-row class="pb-2">
                <b-col sm>
                    <label for="experienciaProfecionalFecha" class="form-label">Fecha de inicio</label>
                    <b-form-input v-model="experiencia.fechaInicio" type="date" id="experienciaProfecionalFecha" required/>
                </b-col>
                <b-col sm>
                    <label for="experienciaProfecionalFechaFin" class="form-label">Fecha de fin</label>
                    <b-form-input v-model="experiencia.fechaFin" type="date" id="experienciaProfecionalFechaFin" required/>
                </b-col>
            </b-row>

            <b-row class="pb-2">
                <b-col sm>
                    <label for="experienciaProfecionalCargo" class="form-label">Cargo</label>
                    <b-form-input v-model="experiencia.cargo" type="text" id="experienciaProfecionalCargo" required/>
                </b-col>
                <b-col sm>
                    <label for="experienciaProfecionalMotivo" class="form-label">Motivo de retiro</label>
                    <b-form-input v-model="experiencia.motivoRetiro" type="text" id="experienciaProfecionalMotivo" required/>
                </b-col>
            </b-row>
            
            <b-row class="pb-2">
                <b-col sm>
                    <label for="experienciaProfecionalTelefono" class="form-label">Teléfono</label>
                    <b-form-input v-model="experiencia.telefono" type="text" id="experienciaProfecionalTelefono" required/>
                </b-col>
                <b-col sm>
                    <label for="experienciaProfecionalJefe" class="form-label">Jefe inmediato</label>
                    <b-form-input v-model="experiencia.jefeInmediato" type="text" id="experienciaProfecionalJefe" required/>
                </b-col>
            </b-row>
            
            <b-row class="pb-2">
                <b-col sm>
                    <label for="educacionNoFPais" class="form-label">País</label>
                    <b-form-select required v-model="experiencia.paisId" text-field="descripcion" value-field="id"
                        :options="listaPaises"
                        @change.native="setDepartamentosInElement( idx, experiencia.paisId)"
                        >
                        <template #first>
                            <b-form-select-option selected :value="null" disabled>
                                Seleccionar
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col sm>
                    <label for="educacionNoFDepartamento" class="form-label">Departamento</label>
                    <b-form-select required v-model="experiencia.departamentoId" text-field="descripcion" value-field="id"
                        :options="departamentos[idx]"
                        @change.native="setMunicipiosInElement( idx, experiencia.departamentoId)"
                        >
                        <template #first>
                            <b-form-select-option selected :value="null" disabled>
                                Seleccionar
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            
            <b-row class="pb-2">
                <b-col sm>
                    <label for="educacionNoFMunicipio" class="form-label">Municipio</label>
                    <b-form-select required v-model="experiencia.municipioId" text-field="descripcion" value-field="id"
                        :options="municipios[idx]"
                        
                        >
                        <template #first>
                            <b-form-select-option selected :value="null" disabled>
                                Seleccionar
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col sm></b-col>
            </b-row>

        </b-form>
        <div class="d-flex flex-row-reverse py-2" style="width: 100%; gap: 5px">
            <button class="btn px-4 py-2 btn-primary" @click="saveAndContinue">
                <span> Guardar y continuar </span>
            </button>
            <button class="btn px-4 py-2 btn-outline-success" @click="agregarRegistro">
                <span> Nuevo registro </span>
            </button>
        </div>
    </div>
</template>
